<template>
  <section class="container-fluid mt-4 admin-dashboard">
    <div class="box">
      <div class="box-header d-flex justify-content-between bg-white">
        <h3>เลือก API ในการดึงข้อมูล</h3>
      </div>
      <div class="box-body">
        <div class="d-flex flex-column">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="ted"
              name="ted"
              v-model="siteSetting"
              :value="1"
            />
            <label class="form-check-label" for="ted">
              Thai energy data
            </label>
          </div>
          <span class="ml-3">API สำหรับการดึงข้อมูลจังหวัดด้วย Thai energy data โดยตรง</span>
        </div>
        <div class="d-flex flex-column">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="mondas"
              name="mondas"
              v-model="siteSetting"
              :value="2"
            />
            <label class="form-check-label" for="mondas">
              Mondas
            </label>
          </div>
          <span class="ml-3">API สำหรับการดึงข้อมูลจังหวัดด้วย Mondas (API from Germany)</span>
        </div>
        <div class="text-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group d-flex justify-content-between">
          <div class="text-success">
            {{ successMessage }}
          </div>
          <button
            class="btn btn-action py-1 px-4"
            v-promise-btn
            @click="updateSiteSetting"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {get} from 'lodash';

export default {
  name: 'ApiSetting',

  data() {
    return {
      siteSetting: null,
      errorMessage: null,
      successMessage: null,
    };
  },
  computed: {
    ...mapGetters(['getSiteSetting']),
  },
  mounted() {
    this.siteSetting = this.getSiteSetting.value;
  },
  methods: {
    ...mapMutations(['setSiteSetting']),
    updateSiteSetting() {
      return window.axios
        .post(`/api/site-setting`, {siteSetting: this.siteSetting})
        .then(({data}) => {
          // this.siteSetting = data.response.value;
          this.setSiteSetting(data.response);
          this.errorMessage = null;
          this.successMessage = `API location changed`;
        })
        .catch((err) => {
          this.siteSetting = null;
          this.errorMessage = err;
          console.log(this.errorMessage);
        });
    },
  },
};
</script>
